<template>
  <div v-if="prescription">
    <validation-observer tag="form" v-slot="{invalid}"
                         ref="formStatus"
                         @submit.prevent="savePrescription"
                         @keydown.enter.prevent>
      <div class="form-group">
        <validation-provider vid="PrescriptionDate" name="Verordnungsdatum" rules="required" v-slot="{errors}">
          <label for="dateInput">Verordnungsdatum</label>
          <input id="dateInput"
                 type="date"
                 v-model="prescriptionDateValue"
                 class="form-control"
                 autofocus
                 ref="firstInput"
                 :class="{'is-invalid': errors.length > 0}"/>
          <form-errors :errors="errors"/>
        </validation-provider>
      </div>
      <div class="form-group">
        <label for="icd10CodeInput">ICD-10 Codes</label>
        <icd10-code-input
            id="icd10CodeInput"
            :icd10-codes="icd10Codes"
            @selected="icd10CodeSelected($event)">
          <template v-slot:suggestion="{suggestion}">
            <span class="badge-pill">{{ suggestion.name }}</span> - {{ suggestion.description }}
          </template>
        </icd10-code-input>
        <span class="badge-pill badge-info mr-1"
              v-for="icd10Code in prescription.icd10Codes"
              :key="icd10Code.id">
          {{ icd10Code.name }}
          <span class="text-light font-weight-bolder link"
                @click="unselectIcd10Code(icd10Code)">
            &times;
          </span>
        </span>
      </div>
      <div class="form-group">
        <label for="indicationKeyInput">Diagnosegruppe</label>
        <select id="indicationKeyInput"
                v-model="prescription.indicationKey"
                class="form-control">
          <option :value="null">-- bisher keine Diagnosegruppe --</option>
          <option v-for="indicationKey in indicationKeys"
                  :key="indicationKey.id"
                  :value="indicationKey"
                  :selected="prescription.indicationKey && (prescription.indicationKey.id === indicationKey.id)">
            {{ indicationKey.name }} - {{ indicationKey.description }}
          </option>
        </select>
      </div>
      <div class="form-group">
        <label for="remediesInput">Heilmittel</label>
        <remedies-input
            id="remediesInput"
            :remedies="remedies"
            @selected="remedySelected($event)">
        </remedies-input>
        <span class="badge-pill badge-info mr-1"
              v-for="prescriptionItem in prescription.prescriptionItems"
              :key="prescriptionItem.id">
          {{ prescriptionItem.remedy.shortName }}
          <span class="text-light font-weight-bolder link"
                @click="unselectRemedy(prescriptionItem.remedy)">
            &times;
          </span>
        </span>
      </div>
      <div class="form-group row">
        <div class="col-6">
          <validation-provider vid="Amount" name="Einheiten" v-slot="{errors}">
            <label for="amountInput">Einheiten</label>
            <input type="text"
                   id="amountInput"
                   v-model.number="prescription.amount"
                   class="form-control"
                   :class="{'is-invalid': errors.length > 0}"/>
          </validation-provider>
        </div>
        <div class="col-6">
          <label for="frequencyInput">Therapiefrequenz (pro Woche)</label>
          <range-slider
              id="frequencyInput"
              :min="1"
              :max="5"
              :step="1"
              :enable-cross="false"
              :minRange="0"
              :marks="true"
              :hide-label="true"
              tooltip="always"
              tooltip-placement="bottom"
              v-model="frequencyRange">
          </range-slider>
        </div>
      </div>
      <div class="text-right pt-4">
        <button class="btn btn-secondary btn-sm mr-1"
                tabindex="-1"
                @click="$router.go(-1)">
          Abbrechen
        </button>
        <button type="submit"
                class="btn btn-primary btn-sm"
                :disabled="invalid"
                @blur="$refs.firstInput.focus()"
                @keydown.enter="savePrescription">
          Speichern
        </button>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import prescriptionService from "@/services/prescription.service";
import {toHtmlFormat, fromHtmlFormat} from "@/utils/date-converter";
import {mapActions} from 'vuex'
import Prescription from '@/models/prescription'
import masterDataService from "@/services/masterdata.service";
import Icd10CodeInput from "@/components/masterdata/Icd10CodeInput";
import RemediesInput from "@/components/masterdata/RemediesInput";
import FormErrors from "@/components/common/FormErrors";
import handleFormError from "@/utils/form-error-converter";

export default {
  props: {
    prescriptionId: String,
    patientId: String
  },
  data() {
    return {
      prescription: undefined,
      icd10Codes: [],
      indicationKeys: [],
      remedies: [],
      frequency: [1, 3]
    }
  },
  computed: {
    prescriptionDateValue: {
      get: function () {
        return toHtmlFormat(this.prescription.prescriptionDate)
      },
      set: function (htmlFormat) {
        this.prescription.prescriptionDate = fromHtmlFormat(htmlFormat)
      }
    },
    frequencyRange: {
      get: function () {
        return [this.prescription.frequencyMin || 1, this.prescription.frequencyMax || 3]
      },
      set: function (range) {
        this.prescription.frequencyMin = range[0]
        this.prescription.frequencyMax = range[1]
      }
    }
  },
  methods: {
    ...mapActions(['handleError']),
    updatePrescription() {
      if (this.prescriptionId)
        prescriptionService.get(this.prescriptionId)
            .then(p => this.prescription = p)
            .catch(error => this.handleError(error))
      else {
        this.prescription = new Prescription()
        this.prescription.personId = this.patientId
        this.prescription.amount = 6
      }
    },
    savePrescription() {
      console.log('save')
      prescriptionService.save(this.prescription)
          .then(p => {
            this.$emit('prescriptionSaved', p)
          })
          .catch(error => {
            if (!handleFormError(error, this.$refs.formStatus))
              this.handleError(error)
          })
    },
    icd10CodeSelected(icd10Code) {
      if (this.prescription.icd10Codes.indexOf(icd10Code) < 0)
        this.prescription.icd10Codes.push(icd10Code)
    },
    unselectIcd10Code(icd10Code) {
      this.prescription.icd10Codes.splice(this.prescription.icd10Codes.indexOf(icd10Code), 1)
    },
    remedySelected(remedy) {
      if (this.prescription.prescriptionItems.filter(r => r.remedy === remedy).length === 0)
        this.prescription.prescriptionItems.push({
          remedy,
          factor: 1
        })
    },
    unselectRemedy(remedy) {
      let prescriptionItem = this.prescription.prescriptionItems.filter(pi => (pi.remedy && (pi.remedy.id === remedy.id)))
      this.prescription.prescriptionItems.splice(this.prescription.prescriptionItems.indexOf(prescriptionItem), 1)
    }
  },
  watch: {
    prescriptionId() {
      this.updatePrescription()
    },
    icd10Code(newVal) {
      if (newVal) {
        this.$nextTick(() => this.icd10CodeSelected(newVal))
      }
    },
    remedy(newVal) {
      if (newVal) {
        this.$nextTick(() => this.remedySelected(newVal))
      }
    }
  },
  mounted() {
    this.updatePrescription()
    masterDataService.getIcd10Codes()
        .then(codes => {
          this.icd10Codes = codes
        })
    masterDataService.getIndicationKeys()
        .then(codes => {
          this.indicationKeys = codes
        })
    masterDataService.getRemedies()
        .then(remedies => {
          this.remedies = remedies
        })
  }
  ,
  components: {
    RemediesInput,
    Icd10CodeInput,
    FormErrors
  }
}
</script>