import store from '@/store'
import Patient from '@/models/patient'


let allPatients = undefined
let filteredPatients = undefined
let lastSearch = ''

class PatientService {

    async getAll() {
        if (!allPatients) {
            await store.getters.api.get('persons')
                .then(response => {
                    allPatients = response.data.data.map(p => new Patient(p))
                    allPatients.sort(Patient.compare)
                })
        }
        return Promise.resolve(allPatients)
    }

    getAllFiltered(filter) {
        return this.getAll().then(function (allPatients) {
            // reset search
            if (!lastSearch || lastSearch.length === 0|| !filter.startsWith(lastSearch)) {
                filteredPatients = allPatients
            }
            // apply search filter
            if (filter && filter.length > 0)
            {
                var params = filter.toLowerCase().split(' ')
                filteredPatients = filteredPatients.filter(function (p) 
                    {
                        for (const param of params)
                            if (!p.name().toLowerCase().includes(param)) return false
                        return true
                    }
                )
            }
            // save search string for incremental search
            lastSearch = filter;
            return filteredPatients
        })
    }

    addPatientToList(patient){
        allPatients.push(patient)
        allPatients.sort(Patient.compare)
    }

    changePatientInList(patient){
        let idx = allPatients.findIndex(p => p.id === patient.id)
        allPatients[idx] = patient
        allPatients.sort(Patient.compare)
    }

    removePatientFromList(patient){
        let idx = allPatients.findIndex(p => p.id === patient.id)
        allPatients.splice(idx, 1)
    }

    save(patient) {
        let url = 'persons' + (patient.id ? `/${patient.id}` : '')
        let isNewPatient = !patient.id ? true : false;
        let method = isNewPatient ? store.getters.api.post : store.getters.api.put
        return method(url, patient)
            .then(response => {
                let p = new Patient(response.data.data)
                if(isNewPatient)
                    this.addPatientToList(p)
                else
                    this.changePatientInList(p)
                return Promise.resolve(p)
            });
    }

    getById(patientId) {
        return store.getters.api.get(`persons/${patientId}`)
            .then(response => Promise.resolve(new Patient(response.data.data)))
    }

    delete(patient) {
        return store.getters.api.delete(`persons/${patient.id}`)
            .then(() => {
                this.removePatientFromList(patient)
                return Promise.resolve()
            });
    }
}

const patientService = new PatientService()

export default patientService
