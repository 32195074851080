<template>
  <explorer-layout>
    <template v-slot:select="{explorerSelect}">
      <div class="mb-2">
        <button class="btn btn-primary btn-sm"
                @click="newUser(explorerSelect)">
          Neuer Benutzer
        </button>
      </div>
      <user-list :users="users" v-model="selectedUser"
                 @select="explorerSelect($event)"/>
    </template>
    <template v-slot:work="{explorerUnselect}">
      <user-editor :user="selectedUser" :roles="roles"
                   @save="saveUser($event)"
                   @cancel="cancelEdit(explorerUnselect)"
                   @delete="deleteUser($event, explorerUnselect)"/>
    </template>
    <template v-slot:no-work>
      <div class="h-100 text-center mt-5 text-secondary">
        Bitte wählen Sie einen Nutzer, um ihn zu bearbeiten
      </div>
    </template>
  </explorer-layout>
</template>

<script>
import ExplorerLayout from "@/layouts/ExplorerLayout";
import UserList from "@/components/user/UserList";
import UserEditor from "@/components/user/UserEditor";
import User from "@/models/user";
import {mapActions} from "vuex";
import userService from "@/services/user.service";
import handleFormError from "@/utils/form-error-converter";

export default {
  data() {
    return {
      users: [],
      selectedUser: null,
      roles: []
    }
  },
  methods: {
    ...mapActions(['showMessage', 'handleError']),
    saveUser(user) {
      userService.save(user)
          .then(() => {
            this.showMessage({
              message: 'Ihre Änderungen wurden gespeichert',
              type: 'info'
            })
            this.loadUsers()
            this.selectedUser = userService.getUser(user)
          })
          .catch(error => {
            if (!handleFormError(error, this.$refs.formStatus))
              this.handleError(error)
          })
    },
    newUser(explorerSelectFunction) {
      let newUser = new User({
        username: null,
        email: null,
        roles: [],
        new: true
      })
      this.selectedUser = newUser;
      explorerSelectFunction('Neuer Benutzer')
    },
    loadUsers() {
      userService.getAll().then(users => {
        this.users = users
      })
    },
    async deleteUser(user, unselectFunction) {
      const username = user.username
      userService.delete(user).then(() => {
        this.showMessage({
          message: 'Der Benutzer ' + username + ' wurde gelöscht.',
          type: 'info'
        })
        unselectFunction()
        this.loadUsers()
      })
    },
    cancelEdit(unselectFunction) {
      this.selectedUser = null
      unselectFunction()
    }
    ,
    hasRole(user, role) {
      return user.roles.find(r => r === role)
    }
  }
  ,
  mounted() {
    this.$store.getters.api.get('roles')
        .then(response => this.roles = response.data)
    this.loadUsers()
  },
  components: {
    UserEditor,
    UserList,
    ExplorerLayout
  }
}
</script>