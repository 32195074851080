import PrescriptionItem from "@/models/prescription-item";
import {fromThea} from '@/utils/date-converter'
export default class Prescription {

    constructor(data) {
        this.id = data ? data.id : 0
        this.personId = data ? data.personId : undefined
        this.patientId = data ? data.personId : undefined
        this.prescriptionDate = data ? fromThea(data.prescriptionDate) : new Date()
        this.amount = data ? data.amount : undefined
        this.frequencyMin = data ? data.frequencyMin : 1
        this.frequencyMax = data ? data.frequencyMax : 3
        this.indicationKey = data ? data.indicationKey : undefined
        this.icd10Codes= data ? data.icd10Codes : []
        this.prescriptionItems = data ? data.prescriptionItems.map(pi => new PrescriptionItem(pi)) : []
        this.prescriptionItems.forEach(pi => pi.remedyId = pi.remedy ? pi.remedy.id : 0)
    }

    static compare(p1, p2) {
        return p1.prescriptionDate.isBefore(p2.prescriptionDate) ? 1 : -1
    }


}

