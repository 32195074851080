<template>
  <div v-if="message"
       class="thea-alert alert fixed-bottom mx-4 mb-4"
       :class="{'alert-danger':danger, 'alert-success':success, 'alert-info': info, 'alert-warning': warning }"
       v-on-clickaway="close">
    {{message}}
    <button type="button" class="close" aria-label="Close" @click="close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
import {mixin as clickaway} from 'vue-clickaway';

export default {
  computed: {
    message() {
      return this.$store.getters.message
    },
    danger() {
      return this.$store.getters.messageType === 'danger'
    },
    success() {
      return this.$store.getters.messageType === 'success'
    },
    info() {
      return this.$store.getters.messageType === 'info'
    },
    warning() {
      return this.$store.getters.messageType === 'warning'
    }
  },
  methods: {
    ...mapActions(['clearMessages']),
    close() {
      this.clearMessages()
    }
  },
  mixins: [clickaway]
}
</script>
