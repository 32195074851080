export default class Note {

    constructor(data) {
        this.id = data && data.id ? data.id : 0
        this.personId = data && data.personId ? data.personId : undefined
        this.patientId = data && data.personId ? data.personId : undefined
        this.author = data && data.author ? data.author : undefined
        this.createdDate = data && data.createdDate ? data.createdDate : undefined
        this.appointmentDate = data && data.appointmentDate ? data.appointmentDate : undefined
        this.typeId = data && data.type ? data.typeId : undefined
        this.value = data && data.value ? data.value : ''
        this.data = data && data.data ? data.data : undefined
    }

    static compare(n1, n2) {
        return n2.createdDate.localeCompare(n1.createdDate)
   }

}

