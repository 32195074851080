export default function handleFormError(error, formStatus) {
    if (error.response && error.response.status && error.response.status >= 400 && error.response.status < 500) {
        let errors = {}
        error.response.data.errors.forEach(err => {
            if (!errors[err.fieldName])
                errors[err.fieldName] = []
            errors[err.fieldName].push(err.message)
        })
        formStatus.setErrors(errors)
        return true
    }
    return false
}