<template>
  <div class="container">
    <nav class="navbar navbar-expand navbar-dark fixed-top bg-primary border-top border-light">
      <div class="navbar-brand">
        <img src="@/assets/logo.svg" width="64px" class="mt-0 pb-1"/>
      </div>
      <ul class="nav navbar-nav">
        <li class="nav-item" v-if="loggedIn">
          <router-link tag="a"
                       :to="{name: 'patients'}"
                       class="nav-link"
                       :class="{active: isSection('patients')}">
            Patienten
          </router-link>
        </li>
        <li class="nav-item" v-if="loggedIn">
          <router-link tag="a" :to="{name: 'appointments'}" class="nav-link">Termine</router-link>
        </li>
        <li class="nav-item" v-if="loggedIn && isAdmin">
          <router-link tag="a" :to="{name: 'usermanagement'}" class="nav-link">Benutzerverwaltung</router-link>
        </li>
      </ul>
      <router-link v-if="!loggedIn && $route.name!=='login'"
                   :to="{name: 'login'}"
                   replace
                   tag="button"
                   class="mr-3 ml-auto btn btn-outline-light">
        Anmelden
      </router-link>
      <div class="mr-0 ml-auto text-right navbar-nav nav-item">
        <li class="nav-link">
          <span @click="$router.push({name: 'changepassword'})"
                class="link">
            {{ user.name }}
          <icon icon="cog"
                size="1x"
                class="ml-1 link"/>
          </span>
        </li>
        <li class="nav-link">
          <icon icon="power-off"
                @click.prevent="logout()"
                size="1x"
                class="ml-1 link"/>
        </li>
      </div>
    </nav>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import authenticationService from "@/services/authentication.service"

export default {
  data() {
    9
    return {
      subMenuVisible: false,
    }
  },
  computed: {
    ...mapGetters(['loggedIn', 'user', 'isAdmin'])
  },
  methods: {
    isSection(name) {
      return this.$route.meta.section === name
    },
    logout() {
      authenticationService.logout()
    }
  },
  watch: {
    $route(to) {
      this.currentRoute = to
    }
  }
}
</script>
