import moment from "moment";

import Prescription from '@/models/prescription'
import {fromThea} from '@/utils/date-converter'

export default class Patient {

    constructor(data) {
        this.id = data ? data.id : 0
        this.firstName = data ? data.firstName : undefined
        this.lastName = data ? data.lastName : undefined
        this.dateOfBirth = data ? fromThea(data.dateOfBirth) : undefined
        this.insurantNumber = data ? data.insurantNumber : undefined
        this.title = data ? data.title : undefined
        this.gender = data ? data.gender : undefined
        this.street = data ? data.street : undefined
        this.streetNumber = data ? data.streetNumber : undefined
        this.addition = data ? data.addition : undefined
        this.zipcode = data ? data.zipcode : undefined
        this.city = data ? data.city : undefined
        this.phone = data ? data.phone : undefined
        this.mobile = data ? data.mobile : undefined
        this.email = data ? data.email : undefined
        this.healthInsurance = data ? data.healthInsurance : undefined
        this.prescriptions = (data && data.prescriptions) ?
            (data.prescriptions.map(p => new Prescription(p))).sort(Prescription.compare) :
            []

    }

    salutation () {
        return (this.gender == 0 || this.gender == 30) ? ''
            : (this.gender == 10 ? 'Herr' : 'Frau')
    }

    name(){
        return this.firstName + ' ' + this.lastName
    }

    getAge () {
        return moment().diff(this.dateOfBirth, 'years')
    }

    static compare(u1, u2) {
        let res = u1.lastName.localeCompare(u2.lastName)
        if (!res)
            res = u1.firstName.localeCompare(u2.firstName)
        return res
    }
}

