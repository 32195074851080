import store from '@/store'
import User from "@/models/user";

class UserService {

    roles = []
    users = []

    async getAll() {
        await this.initRoles()
        if (this.users.length == 0)
            this.users = await store.getters.api.get('users')
                .then(response => {
                    let result = response.data.map(u => new User(u))
                    return Promise.resolve(result.sort(User.compare))
                })
        return this.users
    }

    async save(user) {
        // save user data
        let savedUser = await store.getters.api.post('users', user)
            .then(response => {
                response.data.roles = user.roles
                return Promise.resolve(new User(response.data))
            });
        // find existing user in users[] to determine which roles have to be removed/added
        let userIdx = this.users.findIndex(u => u.username === savedUser.username)
        let existingUser = userIdx >= 0 ? this.users[userIdx] : null
        // assign roles
        for(let r of this.roles)
            if (this.hasRole(savedUser, r))
                await store.getters.api.post(`users/${savedUser.username}/roles`, {role: r})
            else if(existingUser && this.hasRole(existingUser, r))
                await store.getters.api.delete(`users/${savedUser.username}/roles/${r}`, {role: r})
        // replace or add user object in users[]
        if(!existingUser){
            this.users.push(savedUser)
            this.users.sort(User.compare)
        } else {
            this.users[userIdx] = savedUser
        }
    }

    async initRoles() {
        if (this.roles.length === 0)
            this.roles = await store.getters.api.get('roles')
                .then(response => Promise.resolve(response.data))
    }

    hasRole(user, role) {
        return user.roles.findIndex(r => r === role) >= 0
    }

    delete(user) {
        return store.getters.api.delete(`users/${user.username}`)
            .then(() => {
                this.users.splice(this.users.findIndex(u => u.username === user.username), 1)
                return Promise.resolve()
            });
    }

    getUser(user) {
        return this.users.find(u => u.username === user.username);
    }
}

const userService = new UserService()

export default userService
