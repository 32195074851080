<template>
  <div class="vertical-scroll">
    <div id="nav" class="no-print center">
      <router-link to="/stats">Mitarbeiterstatistik</router-link> |
      <router-link to="/manual">Anleitung</router-link>
    </div>
    <router-view />
    <div class="container">
      <!--UPLOAD-->
      <form
        enctype="multipart/form-data"
        novalidate
        v-if="isInitial || isSaving"
      >
        <h1 class="center">CSV-Datei auswählen</h1>
        <div class="dropbox">
          <input
            type="file"
            multiple
            :name="uploadFieldName"
            :disabled="isSaving"
            @change="
              filesChange($event.target.name, $event.target.files);
              fileCount = $event.target.files.length;
            "
            accept="*.csv"
            class="input-file"
          />
          <p v-if="isInitial">
            Datei(en) hier her ziehen<br />
            oder zum Auswählen klicken
          </p>
          <p v-if="isSaving">{{ fileCount }} Dateien werden verarbeitet ...</p>
        </div>
      </form>
      <!--IMPORTED-->
      <div v-if="isImported">
        <h2 class="center">{{ importedData.length }} Datensätze erkannt</h2>
        <h3 class="center">{{ uploadErrors.length }} Fehler</h3>
        <table class="center" v-if="uploadErrors.length > 0">
          <tr>
            <th>Beleg</th>
            <th>Datum</th>
            <th>Patient</th>
            <th class="number">Anzahl</th>
            <th>Heilmittel</th>
            <th>Rechnungsnummer</th>
            <th>Therapeuten</th>
            <th class="number">Betrag</th>
            <th>Fehler</th>
          </tr>
          <tr
            v-for="item in uploadErrors"
            :key="item[1]"
            v-bind:class="{ error: item.Errors.length > 0 }"
          >
            <td>{{ item.PrescriptionId }}</td>
            <td>{{ item.PrescriptionDate }}</td>
            <td>{{ item.PersonName }}</td>
            <td class="number">{{ item.PrescriptionAmount }}</td>
            <td>{{ item.Remedy }}</td>
            <td>{{ item.InvoiceNumber }}</td>
            <td>{{ item.Therapists }}</td>
            <td class="number">{{ formatAsCurrency(item.Value) }}</td>
            <td>
              <ul>
                <li v-for="error in item.Errors" :key="error">{{ error }}</li>
              </ul>
            </td>
          </tr>
        </table>
        <p class="center">
          <button class="purple fancy" @click="reset">
            Zurück
          </button>
          <button id="stats" class="purple fancy" @click="showStats">
            Statistik erzeugen
          </button>
        </p>
      </div>
      <!--STATS-->
      <div v-if="isStats" ref="content">
        <div class="no-print">
          <h2 class="center">
            Mitarbeiterstatistik für {{ employeeStats.length }} Therapeuten
            erzeugt
          </h2>
          <div class="center">
            <button class="purple therapist" @click="selectAllTherapists()">
              Alle
            </button>
            <button
              class="purple therapist"
              v-for="item in employeeStats"
              :key="item.Therapist"
              @click="selectTherapist(item.Therapist)"
            >
              Therapeut {{ item.Therapist }}
            </button>
          </div>
        </div>
        <div
          class="therapistStats"
          v-for="item in filteredTherapists"
          :key="item.Therapist"
        >
          <h3 class="center">Therapeut {{ item.Therapist }}</h3>
          <table class="center">
            <tr>
              <th class="number">Beleg</th>
              <th>Datum</th>
              <th>Patient</th>
              <th>Zuzahlung</th>
              <th>Heilmittel</th>
              <!--<th>Rechnungsnummer</th>-->
              <th class="number">Betrag</th>
            </tr>
            <template v-for="benefit in item.Benefits">
              <tr :key="benefit.Uuid">
                <td class="number min-w60">{{ benefit.PrescriptionId }}</td>
                <td>{{ benefit.PrescriptionDate }}</td>
                <td>{{ benefit.PersonName }}</td>
                <td class="w60">{{ benefit.CoPayment > 0 ? "Ja" : "Nein" }}</td>
                <td>{{ benefit.PrescriptionAmount }} x {{ benefit.Remedy }}</td>
                <!--<td>{{ benefit.InvoiceNumber }}</td>-->
                <td class="currency">{{ formatAsCurrency(benefit.Value) }}</td>
              </tr>
              <tr v-for="subBenefit in benefit.Benefits" :key="subBenefit.Uuid">
                <td colspan="4" />
                <td>
                  {{ subBenefit.PrescriptionAmount }} x {{ subBenefit.Remedy }}
                </td>
                <!--<td>{{ subBenefit.InvoiceNumber }}</td>-->
                <td class="currency">
                  {{ formatAsCurrency(subBenefit.Value) }}
                </td>
              </tr>
            </template>
            <tr>
              <td colspan="7" class="currency main-entry">
                Gesamt Brutto: {{ formatAsCurrency(item.Value) }}
              </td>
            </tr>
          </table>
        </div>
        <p class="center">
          <button class="purple fancy no-print" @click="reset">
            Zurück
          </button>
        </p>
      </div>
      <!--FAILED-->
      <div v-if="isFailed">
        <h2>Uploaded failed.</h2>
        <p>
          <a @click="reset">Try again</a>
        </p>
        <pre>{{ uploadError }}</pre>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import VuePapaParse from 'vue-papa-parse'
import TransformationService from '@/services/transformation.service.js'
import NumberUtil from '@/utils/number-util.js'

Vue.use(VuePapaParse)

const STATUS_INITIAL = 0
const STATUS_SAVING = 1
const STATUS_IMPORTED = 2
const STATUS_FAILED = 3
const STATUS_STATS = 4

export default {
  name: 'Thea-Mitarbeiterstatistik',
  data () {
    return {
      selectedTherapists: [],
      importedData: [],
      employeeStats: [],
      uploadErrors: [],
      currentStatus: null,
      uploadFieldName: 'photos'
    }
  },
  computed: {
    isInitial () {
      return this.currentStatus === STATUS_INITIAL
    },
    isSaving () {
      return this.currentStatus === STATUS_SAVING
    },
    isImported () {
      return this.currentStatus === STATUS_IMPORTED
    },
    isFailed () {
      return this.currentStatus === STATUS_FAILED
    },
    isStats () {
      return this.currentStatus === STATUS_STATS
    },
    filteredTherapists () {
      return this.employeeStats.filter((therapist) =>
        this.selectedTherapists.includes(therapist.Therapist)
      )
    }
  },
  methods: {
    formatAsCurrency (num) {
      return NumberUtil.formatAsCurrency(num)
    },
    selectTherapist (therapist) {
      this.selectedTherapists = [therapist]
    },
    selectAllTherapists () {
      this.selectedTherapists = this.employeeStats.map((x) => x.Therapist)
    },
    reset () {
      // reset form to initial state
      this.currentStatus = STATUS_INITIAL
      this.importedData = []
      this.employeeStats = []
      this.uploadErrors = []
      this.selectedTherapists = []
    },
    showStats () {
      this.generateStats()
      this.currentStatus = STATUS_STATS
    },
    filesChange (fieldName, fileList) {
      if (!fileList.length) return
      // import files
      for (var file in Array.from(fileList)) {
        this.import(fileList[file])
      }
      this.currentStatus = STATUS_IMPORTED
    },
    import (file) {
      var vm = this
      // upload data to the server
      this.currentStatus = STATUS_SAVING
      this.$papa.parse(file, {
        delimiter: ';',
        encoding: 'ISO-8859-1',
        complete: function (results) {
          // parse data
          var parsedData = TransformationService.parseFile(results.data)
          vm.importedData = vm.importedData.concat(parsedData)
          // Validate data
          for (var i in parsedData) {
            var item = parsedData[i]
            TransformationService.validateData(item)
            if (item.Errors.length > 0) {
              vm.uploadErrors.push(item)
            }
          }
        }
      })
    },
    generateStats () {
      var result = []
      for (var i in this.importedData) {
        result = result.concat(
          TransformationService.transformBenefit(this.importedData[i])
        )
      }
      this.employeeStats = TransformationService.groupBenefitsByTherapist(
        result
      ).sort((a, b) => (a.Therapist > b.Therapist ? 1 : -1))
      for (var j in this.employeeStats) {
        this.employeeStats[j].Benefits = TransformationService.groupPrescriptionsByPrescription(
          this.employeeStats[j].Benefits
        )
        this.employeeStats[j].Benefits.sort((a, b) =>
          a.PrescriptionId > b.PrescriptionId ? 1 : -1
        )
      }
      this.selectAllTherapists()
    }
  },
  mounted () {
    this.reset()
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/styles/stats.scss';
</style>