<template>
  <div>
    <div v-if="!editedNote" class="row">
      <div class="col-12 text-right">
        <button class="btn btn-primary btn-sm link"
                @click="editNewNote">
          Neue Dokumentation
        </button>
      </div>
    </div>
    <validation-observer v-else tag="div" ref="formStatus">
      <validation-provider vid="Value" v-slot="{errors}">
        <note-editor v-model="editedNote"
                     @cancel="editedNote = null"
                     @editingFinished="saveNote($event)"/>
        <form-errors :errors="errors"/>
      </validation-provider>
    </validation-observer>
    <div v-for="note in notes"
         :key="note.id"
         class="row">
      <note-detail :note="note"
                   class="mb-3 border-bottom w-100"
                   @saved="updateNotes"
                   @deleted="updateNotes"/>
    </div>
    <div v-if="notes.length === 0 && !editedNote"
         class="text-secondary">
      Noch keine Dokumentationen
    </div>
  </div>
</template>

<script>
import NoteDetail from "@/components/note/NoteDetail";
import noteService from "@/services/note.service";
import {mapActions} from 'vuex'
import Note from "@/models/note";
import NoteEditor from "@/components/note/NoteEditor";
import FormErrors from "@/components/common/FormErrors";
import handleFormError from "@/utils/form-error-converter";

export default {
  props: {
    patientId: String
  },
  data() {
    return {
      notes: [],
      editedNote: null
    }
  },
  methods: {
    ...mapActions(['showMessage', 'handleError']),
    editNewNote() {
      let note = new Note()
      note.patientId = this.patientId
      this.editedNote = note
    },
    updateNotes() {
      noteService.getNotesByPatient(this.patientId)
          .then(notes => this.notes = notes)
          .catch(error => this.handleError(error))
    },
    saveNote(note) {
      note.personId = this.patientId
      noteService.saveNote(note).then(() => {
        this.editedNote = null
        this.showMessage({
          type: 'info',
          message: 'Ihre Änderungen wurden gespeichert'
        })
        this.updateNotes()
      }).catch(error => {
        console.log('Test')
        if (!handleFormError(error, this.$refs.formStatus))
          this.handleError(error)
      })
    }
  },
  watch: {
    patientId() {
      this.updateNotes()
    }
  },
  mounted() {
    this.updateNotes()
  },
  components: {
    NoteEditor,
    NoteDetail,
    FormErrors
  }

}
</script>