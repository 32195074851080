<template>
  <div class="card"
       v-if="patient">
    <div class="card-header py-2 d-flex">
      <div>
        <icon icon="file-alt"
              size="2x"
              class="py-1 text-info align-middle"/>
        Patientenakte {{ patient.firstName }} {{ patient.lastName }}
      </div>
      <div class="ml-auto mr-0">
        <a href='#' @click.prevent="deletePatient" class="pl-1">
          <icon icon="times-circle"
                size="1x"
                class="text-danger"/>
        </a>
      </div>
    </div>
    <div class="mt-0 ml-0 d-flex bg-light border-bottom border-secondary">
      <router-link
          tag="span"
          class="file-tab"
          :class="{'active': $route.meta.fileSection === 'detail'}"
          :to="{name: 'patientDetail', params: {patientId: patient.id}}">
        Kontaktdaten
      </router-link>
      <router-link
          tag="span"
          class="file-tab"
          :class="{'active': $route.meta.fileSection === 'notes'}"
          :to="{name: 'patientNotes', params: {patientId: patient.id}}">
        Dokumentation
      </router-link>
      <router-link
          tag="span"
          class="file-tab"
          :class="{'active': $route.meta.fileSection === 'prescriptions'}"
          :to="{name: 'patientFilePrescriptions', params: {patientId: patient.id}}">
        Verordnungen
      </router-link>
    </div>
    <div class="card-body pt-0">
      <router-view
          class="mt-3"
          :patient="patient"
          @patientChanged="$emit('patientChanged', $event)"/>
    </div>
  </div>
</template>

<script>
import patientService from "@/services/patient.service";

export default {
  props: {
    patient: Object
  },
  methods: {
    deletePatient() {
      let name = this.patient.name()
      this.$dialog.confirm({
        title: 'Wirklich löschen?',
        body: `Möchten Sie die Patientenakte von ${name} wirklich löschen?`
      }, {
        okText: 'Patientienakte löschen',
        cancelText: 'Nicht löschen'
      })
          .then(() => {
            patientService.delete(this.patient)
            this.$emit('patientDeleted', this.patient.name())
          })
          .catch(() => console.log('deletionAborted'))
    }
  }
}
</script>
