// Vue imports
import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import '@/registerServiceWorker'

// Bootstrap and Icons
import 'bootstrap'
import '@/plugins/vue-fontawesome'

// Form validation
import {ValidationProvider, ValidationObserver} from 'vee-validate';
import '@/validation.js'

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

// vuejs-dialog
import VueDialog from 'vuejs-dialog';
import 'vuejs-dialog/dist/vuejs-dialog.min.css';
Vue.use(VueDialog);

import VueRangeSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css'
Vue.component('rangeSlider', VueRangeSlider)

import errorService from '@/services/error.service'

Vue.config.errorHandler = errorService;
Vue.config.productionTip = false;

import moment from 'moment'

Vue.filter('date', function (value) {
    if (value) {
        return moment(String(value)).format('DD.MM.YYYY')
    }
})

store.dispatch('initApi')

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
