<template>
  <div class="container-fluid mx-0 fill">
    <div class="row">
      <div class="h1 mt-2 col-12">Termine</div>
      <small class="mt-2 col-12">noch nicht implementiert</small>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  methods: {}
}
</script>
