<template>
  <div v-if="prescription" class="border-bottom border-secondary pb-3 px-2">
    <div class="d-flex">
      <div class="font-weight-bolder">
        Verordnung vom {{ prescription.prescriptionDate | date }}
      </div>
      <span class="mr-0 ml-auto text-right">
        <router-link tag="a"
                     :to="{name: 'patientFilePrescriptionEdit', params: {prescriptionId: prescription.id, patientId: prescription.personId}}"
                     class="link">
          <icon icon="edit"
                class="text-primary"/>
        </router-link>
        <a href="#" @click.prevent="deletePrescription"
           class="link">
          <icon icon="times-circle"
                size="1x"
                class="text-danger"/>
        </a>
        </span>
    </div>
    <div class="row">
      <div class="col-6">
        <small>Diagnose{{ prescription.icd10Codes.length > 1 ? 'n' : '' }}</small>
        <div v-if="prescription.icd10Codes.length > 0">
          <div v-for="code in prescription.icd10Codes" :key="code.id">
            <span class="badge-pill badge-info small">{{ code.name }}</span>
            {{ code.description }}
          </div>
        </div>
        <div v-else>
          ---
        </div>
        <small>Diagnosegruppe</small>
        <div v-if="prescription.indicationKey">
          <span class="badge-pill badge-info small">{{ prescription.indicationKey.name }}</span>
          {{ prescription.indicationKey.description }}
        </div>
        <div v-else>
          ---
        </div>
      </div>
      <div class="col-6">
        <small>Heilmittel</small>
        <div v-if="validPrescriptionItems.length > 0">
          <div v-for="item in validPrescriptionItems"
               :key="item.id">
            <span class="badge-pill badge-info small">{{ item.remedy.shortName }}</span>
            {{ item.remedy.name }}
          </div>
        </div>
        <div v-else>
          ---
        </div>
        <div>
          <small>Behandlung</small>
          <div>{{ prescription.amount }} Einheit{{ prescription.amount !== 1 ? 'en' : '' }}</div>
          <div>{{
              prescription.frequencyMin
            }}{{
              prescription.frequencyMax && prescription.frequencyMin !== prescription.frequencyMax ? '-' + prescription.frequencyMax : ''
            }}&times;
            pro Woche
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-6">
        <small>Dokumentation</small>
      </div>
      <div class="col-6 text-right">
        <icon icon="plus-circle"
              class="text-primary link"
              @click="editNewNote"/>
      </div>
    </div>
    <div class="row">
      <div v-if="editedNote" class="col-12">
        <note-editor v-if="editedNote"
                     v-model="editedNote"
                     @editingFinished="saveNote"
                     @cancel="editedNote = null"/>
      </div>
      <div v-if="this.notes && this.notes.length > 0" class="col-12">
        <div v-for="note in notes" :key="note.id" class="bg-light border border-secondary p-1 mb-1">
          <note-detail :note="note"
                       :patient-id="prescription.personId"
                       @saved="noteChanged($event)"
                       @deleted="noteChanged($event)"/>
        </div>
      </div>
      <div v-else class="col-12">
        <small>Noch keine Dokumentation</small>
      </div>
    </div>
  </div>
</template>

<script>
import prescriptionService from "@/services/prescription.service";
import noteService from "@/services/note.service";
import {mapActions} from 'vuex'
import NoteDetail from "@/components/note/NoteDetail";
import NoteEditor from "@/components/note/NoteEditor";
import Note from "@/models/note";

export default {
  components: {NoteEditor, NoteDetail},
  props: {
    prescriptionId: String
  },
  data() {
    return {
      prescription: undefined,
      notes: [],
      editedNote: undefined
    }
  },
  computed: {
    validPrescriptionItems() {
      return this.prescription.prescriptionItems.filter(i => i.remedy)
    }
  },
  methods: {
    ...mapActions(['handleError']),
    updateView() {
      this.updatePrescription()
      this.updateNotes()
    },
    updatePrescription() {
      prescriptionService.get(this.prescriptionId)
          .then(p => this.prescription = p)
          .catch(error => this.handleError(error))
    },
    updateNotes() {
      noteService.getNotesByPrescription(this.prescriptionId)
          .then(notes => this.notes = notes)
          .catch(error => this.handleError(error))
    },
    deletePrescription() {
      this.$dialog.confirm({
        title: 'Verordnung wirklich löschen?',
        body: `Möchten Sie diese Verordnung wirklich löschen?`
      }, {
        okText: 'Verordnung löschen',
        cancelText: 'Nicht löschen'
      })
          .then(() => {
            prescriptionService.deletePrescription(this.prescriptionId)
                .then(() => {
                  this.$emit('prescriptionDeleted', this.prescriptionId)
                })
                .catch(error => this.handleError(error))
          })
          .catch(() => console.log('deletionAborted'))
    },
    editNewNote() {
      let note = new Note()
      note.patientId = this.prescription.patientId
      note.prescriptionId = this.prescriptionId
      this.editedNote = note
    },
    saveNote() {
      noteService.saveNote(this.editedNote)
          .then(() => {
            this.editedNote = undefined
            this.noteChanged()
          })
    },
    noteChanged() {
      this.updateNotes()
    }
  },
  watch: {
    prescriptionId() {
      this.updateView()
    }
  },
  mounted() {
    this.updateView()
  }
}
</script>