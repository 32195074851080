<template>
  <div v-if="editedNote">
    <div class="form-group">
      <label for="valueEdit">Dokumentation</label>
      <textarea id="valueEdit"
                class="form-control"
                v-model="editedNote.value"
                rows="20"
                autofocus/>
    </div>
    <div class="form-group text-right">
      <button class="btn btn-secondary btn-sm" tabindex="-1" @click="$emit('cancel')">Abbrechen</button>
      <button class="btn btn-primary btn-sm ml-1" @click="finishedEditing">Speichern</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: Object
  },
  data() {
    return {
      editedNote: undefined
    }
  },
  methods: {
    updateEditedNote() {
      if (this.value)
        this.editedNote = JSON.parse(JSON.stringify(this.value))
    },
    finishedEditing() {
      this.$emit('input', this.editedNote)
      this.$emit('editingFinished', this.editedNote)
    }
  },
  watch: {
    value() {
      this.updateEditedNote()
    }
  },
  mounted() {
    this.updateEditedNote()
  }
}
</script>