export default class User {

    constructor(data) {
        this.username = data.username;
        this.email = data.email;
        this.roles = data.roles;
        this.new = data.new;
    }

    static compare(u1, u2) {
        return u1.username.localeCompare(u2.username)
    }

}