<template>
  <div class="mb-1">
    <auto-suggest
        v-model="icd10CodeFilter"
        :suggestions="filteredIcd10Codes"
        :input-props="{placeholder: 'Tippen, um Vorschläge zu erhalten...'}"
        :component-attr-class-autosuggest-results-container="autoSuggestResultsContainerClass"
        component-attr-class-autosuggest-results="results"
        @selected="onSelected($event.item)"
        @closed="icd10CodeFilter = ''">
      <template slot-scope="{suggestion}">
        <span class="font-weight-bold">{{ suggestion.item.name }}</span> -
        {{ suggestion.item.description }}
      </template>
    </auto-suggest>
  </div>
</template>

<script>
import {VueAutosuggest} from "vue-autosuggest"

export default {
  props: {
    icd10Codes: Array
  },
  data() {
    return {
      icd10CodeFilter: undefined,
    }
  },
  computed: {
    autoSuggestResultsContainerClass() {
      if (this.filteredIcd10Codes.length > 0)
        return 'auto-suggest-results-container'
      return ''
    },
    filteredIcd10Codes() {
      if (this.icd10CodeFilter) {
        let filtered = this.icd10Codes.filter(c =>
            c.name.toLowerCase().indexOf(this.icd10CodeFilter.toLowerCase()) >= 0)
        return [{
          data: filtered
        }]
      } else {
        return []
      }
    }
  },
  methods: {
    onSelected(item) {
      this.$emit('selected', item)
      this.icd10CodeFilter = ''
    }
  },
  components: {
    autoSuggest: VueAutosuggest
  }
}
</script>
<style scoped>

/deep/ ul {
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0.5rem 0 .5rem 0;
}

/deep/ li {
  margin: 0 0 0 0;
  padding: 0.3rem 0 0.3rem 0.3rem;
  display: flex;
  align-items: center;
}

li:hover {
  cursor: pointer;
}

</style>

