const API_ERROR = 'Thea hat ein technisches Problem bei der Server-Verbindung festgestellt. ' +
    'Bitte laden Sie die Seite neu. ' +
    'Sollte das Problem dadurch nicht behoben sein, kontaktieren Sie bitte den Support!'

const INT_ERROR = 'Es ist ein unerwarteter Fehler aufgetreten. Bitte kontaktieren Sie den Support!'

export default {
    state: {
        message: null,
        type: null,
    },
    mutations: {
        setMessage(state, message) {
            if (message == null)
                state.message = null;
            else {
                state.message = message.message;
                state.type = message.type;
            }
        }
    },
    actions: {
        showMessage({commit}, message) {
            commit('setMessage', message);
        },
        handleError({dispatch}, err) {
            window.console.log('handleErrorAction(' + err + ')')
            let msg = ''
            let type = 'danger'
            if (err.response) {
                msg = err.response.data
                if (err.response.status < 500)
                    type = 'warning'
            } else if (err.request) {
                // client never received a response, or request never left
                msg = API_ERROR
            } else {
                // anything else
                msg = INT_ERROR
            }
            dispatch('showMessage', {
                message: msg,
                type: type
            })
        },
        clearMessages({commit}) {
            commit('setMessage', null);
        }
    },
    getters: {
        message(state) {
            return state.message;
        },
        messageType(state) {
            return state.type;
        }
    }
}
