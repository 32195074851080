import store from '@/store'
import Identity from "@/models/identity";

class AuthenticationService {

    login(username, password) {
        this.logout()
        return store.getters.api.post('identity/login', {username, password})
            .then(response => {
                if (response.data) {
                    store.commit('tokenDetermined', response.data)
                }
                store.commit('loginSuccess', new Identity(username, response.data.token))
                return Promise.resolve(response.data.token)
            })
    }

    logout() {
        store.commit('loggedOut')
        store.commit('tokenInvalidated')
    }

    changePassword(oldPassword, newPassword){
        return store.getters.api.patch('identity/changePassword',{
            oldPassword, newPassword
        })
    }

}

const authenticationService = new AuthenticationService()

export default authenticationService
