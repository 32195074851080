<template>
  <div>
    <div v-if="!editedNote">
      <div class="small">
        {{ note.createdDate | date }} - {{ note.author }}
      </div>
      <div class="row">
        <div class="col-10">
          <span class="text-formatted" v-if="note">{{ note.value }}</span>
        </div>
        <div class="col-2 text-right">
          <icon icon="edit"
                class="text-primary link"
                @click="editNote"/>
          <a href='#' @click.prevent="deleteNote" class="pl-1">
            <icon icon="times-circle"
                  size="1x"
                  class="text-danger"/>
          </a>
        </div>
      </div>
    </div>
    <validation-observer v-else tag="div" ref="formStatus">
      <validation-provider vid="Value" v-slot="{errors}">
        <note-editor v-model="editedNote"
                     @editingFinished="saveNote"
                     @cancel="resetEdit"/>
        <form-errors :errors="errors"/>
      </validation-provider>
    </validation-observer>
  </div>
</template>

<script>
import NoteEditor from "@/components/note/NoteEditor";
import noteService from "@/services/note.service";
import {mapActions} from 'vuex'
import handleFormError from "@/utils/form-error-converter";
import FormErrors from "@/components/common/FormErrors";

export default {
  props: {
    patientId: String,
    note: Object
  },
  data() {
    return {
      editedNote: undefined
    }
  },
  methods: {
    ...mapActions(['showMessage', 'handleError']),
    deleteNote() {
      this.$dialog.confirm({
        title: 'Dokumentation löschen?',
        body: 'Möchten Sie diese Dokumentation wirklich löschen?'
      }, {
        reverse: false,
        okText: 'Ja, löschen',
        cancelText: 'Nicht löschen'
      }).then(() => {
        noteService.deleteNote(this.note)
            .then(() => {
              this.showMessage({
                type: 'info',
                message: 'Die Dokumentation wurde gelöscht'
              })
              this.$emit('deleted', this.note)
            })
            .catch(error => this.handleError(error))
      })
    },
    saveNote() {
      noteService.saveNote(this.editedNote).then(() => {
        this.showMessage({
          type: 'info',
          message: 'Ihre Änderungen wurden gespeichert'
        })
        this.$emit('saved', this.editedNote)
        this.resetEdit()
      }).catch(error => {
        console.log('NodeDetail error')
        if (!handleFormError(error, this.$refs.formStatus))
          this.handleError(error)
      })
    },
    resetEdit() {
      this.editedNote = null
    },
    editNote() {
      this.editedNote = JSON.parse(JSON.stringify(this.note))
    }
  },
  components: {
    NoteEditor, FormErrors
  }
}
</script>