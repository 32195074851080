export default class PrescriptionItem {

    constructor(data) {
        this.id = data ? data.id : 0
        this.factor = data ? data.factor : undefined
        this.remedy = data ? data.remedy : undefined
    }

}

