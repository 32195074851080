<template>
  <div class="container-fluid fill">
    <div class="row fill">
      <div class="col-12 col-md-4 pt-5 pt-md-2 bg-light fill"
           :class="{'d-none d-md-block': workHeader}">
        <slot name="select" :explorer-select="select"></slot>
      </div>
      <div class="col-12 col-md-8 pt-5 pt-md-3 h-100 vertical-scroll"
           :class="{'d-none': !workHeader}">
        <slot name="work-header" :explorer-unselect="unselect">
          <div v-if="workHeader"
               class="d-md-none border-bottom border-secondary text-primary pb-1 mb-3 d-flex"
               @click="unselect">
            <div class="align-content-stretch align-middle link">
              <icon icon="angle-left" class="text-primary h-100"/>
            </div>
            <div class="flex-fill ml-3">
              {{ workHeader }}
            </div>
          </div>
        </slot>
        <slot name="work" :explorer-unselect="unselect"/>
      </div>
      <div class="d-none d-md-block col-md-8"
           :class="{'d-none': workHeader}">
        <slot name="no-work" v-if="!workHeader"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      workHeader: undefined
    }
  },
  methods: {
    select(workHeader) {
      this.workHeader = workHeader
    },
    unselect() {
      this.workHeader = undefined
    }
  }
}
</script>