<template>
  <div v-if="editedUser">
    <div class="card">
      <div class="card-header">
        <div>
          <icon icon="user"
                size="2x"
                class="py-1 mr-1 text-info align-middle"/>
          <span v-if="!editedUser.new">
           Benutzer bearbeiten - {{ user.username }}
          </span>
          <span v-else>Neuen Benutzer anlegen</span>
        </div>
      </div>
      <div class="card-body">
        <validation-observer tag="form"
                             v-slot="{invalid}"
                             ref="formStatus"
                             @submit.prevent="submit"
                             @keydown.enter.prevent>
          <div class="form-group row">
            <div class="col-12 col-md-8">
              <label for="usernameInput">Benutzername</label>
              <validation-provider vid="Username" name="Benutzername" rules="required" v-slot="{errors}">
                <input
                    v-model="editedUser.username"
                    id="usernameInput"
                    class="form-control"
                    type="text"
                    ref="firstInput"
                    :disabled="!editedUser.new"
                    autofocus>
                <form-errors :errors="errors"/>
              </validation-provider>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-12 col-md-8">
              <label for="emailInput">Email</label>
              <validation-provider vid="Email" name="Email" rules="required" v-slot="{errors}">
                <input
                    v-model="editedUser.email"
                    id="emailInput"
                    class="form-control"
                    type="email"
                    :disabled="!editedUser.new">
                <form-errors :errors="errors"/>
              </validation-provider>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-5 form-inline" v-for="(role, idx) in roles" :key="role">
              <label :for="'role_' + idx"
                     class="form-inline mr-1">
                {{ roles[idx] }}</label>
              <input
                  :value="roles[idx]"
                  v-model="editedUser.roles"
                  :id="'role_' + idx"
                  class="col-1 form-inline form-control form-check-input form-check form-control-sm"
                  type="checkbox">
            </div>
          </div>
          <div class="text-right">
            <button class="btn btn-danger mr-4"
                    tabindex="-1"
                    @click.prevent="remove(user)"
                    v-if="!editedUser.new">
              Benutzer löschen
            </button>
            <button class="btn btn-secondary ml-2"
                    @click.prevent="$emit('cancel')"
                    tabindex="-1">
              Abbrechen
            </button>
            <button type="submit"
                    class="btn btn-primary ml-2"
                    :disabled="invalid"
                    @keydown.enter="submit"
                    @blur="$refs.firstInput.focus()">
              Speichern
            </button>
          </div>
        </validation-observer>
      </div>
    </div>
  </div>
</template>

<script>
import FormErrors from "@/components/common/FormErrors";

export default {
  props: {
    user: Object,
    roles: Array
  },
  data() {
    return {
      editedUser: null,
      passwordRepeat: ''
    }
  },
  methods: {
    submit() {
      this.$emit('save', this.editedUser)
    },
    clone(u) {
      this.editedUser = JSON.parse(JSON.stringify(u))
    },
    remove(u) {
      this.$dialog.confirm({
        title: 'Löschen',
        body: `Wollen sie den Nutzer ${u.username} wirklich löschen?`
      }, {
        reverse: false,
        okText: 'Ja',
        cancelText: 'Nein, nicht löschen'
      }).then(() => {
        this.$emit('delete', u)
      })
    }
  },
  watch: {
    user: {
      handler: 'clone'
    }
  },
  mounted() {
    this.clone(this.user)
  },
  components: {
    FormErrors
  }
}
</script>