<template>
  <div class="list-group vertical-scroll h-75">
    <div v-for="user in users"
       :key="user.username"
       class="list-group-item list-group-item-action"
       @click="select(user)"
       :class="{'active': user === value}">
      <icon icon="user"
            size="1x"
            :class="{'text-primary': !value || user !== value,
                  'text-light': value && user === value}"/>
      {{ user.username }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    users: Array,
    value: Object
  },
  methods: {
    select(user) {
      this.$emit('input', user)
      this.$emit('select', user.username)
    }
  }
}
</script>