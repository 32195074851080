<template>
  <div class="mb-1">
    <auto-suggest
        v-model="remedyFilter"
        :suggestions="filteredRemedies"
        :input-props="{placeholder: 'Tippen, um Vorschläge zu erhalten...'}"
        :component-attr-class-autosuggest-results-container="autoSuggestResultsContainerClass"
        component-attr-class-autosuggest-results="results"
        @selected="onSelected($event.item)">
      <template slot-scope="{suggestion}">
        <span class="font-weight-bold">{{ suggestion.item.shortName }}</span> -
        {{ suggestion.item.name }}
      </template>
    </auto-suggest>
  </div>
</template>

<script>
import {VueAutosuggest} from 'vue-autosuggest'

export default {
  props: {
    remedies: Array
  },
  data() {
    return {
      remedyFilter: undefined
    }
  },
  computed: {
    autoSuggestResultsContainerClass() {
      if (this.filteredRemedies.length > 0)
        return 'auto-suggest-results-container'
      return ''
    },
    filteredRemedies() {
      if (this.remedyFilter) {
        let res = this.remedies.filter(c =>
            c.name.toLowerCase().indexOf(this.remedyFilter.toLowerCase()) >= 0
            || c.shortName.toLowerCase().indexOf(this.remedyFilter.toLowerCase()) >= 0)
        return [{
            data: res
          }]
      } else {
        return []
      }
    }
  },
  methods: {
    onSelected(item) {
      this.$emit('selected', item)
      this.remedyFilter = ''
    }
  },
  components: {
    autoSuggest: VueAutosuggest
  }
}
</script>
<style scoped>

/deep/ ul {
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0.5rem 0 .5rem 0;
}

/deep/ li {
  margin: 0 0 0 0;
  padding: 0.3rem 0 0.3rem 0.3rem;
  display: flex;
  align-items: center;
}

li:hover {
  cursor: pointer;
}

</style>