const uuidv4 = require('uuid/v4')
const mappingTTools = {
  'Datum': -1,
  'Belegnummer': -1,
  'Rezeptstatus': -1,
  'Kunde': -1,
  'Anzahl': -1,
  'Therapieart': -1,
  'Zuzahlung': -1,
  'Preis': -1,
  'Abrechnung Datum': -1,
  'Rechnungsnummer (Einzel-/Sammelrechnung)': -1,
  'Nr.': -1,
  'Heilmittelposition': -1,
  'Therapeutennummer': -1,
  'Bemerkung': -1
}

export default {
  parseFile (file) {
    var mappedFieldCount = 0
    var parsedData = []
    for (var row in file) {
      var rowData = file[row]
      if (row === '0') {
        for (var i in rowData) {
          if (rowData[i] in mappingTTools) {
            mappingTTools[rowData[i]] = i
            mappedFieldCount++
          }
        }
      } else if (rowData.length >= mappedFieldCount) {
        parsedData.push(this.transformData(rowData))
      }
    }
    return parsedData
  },

  transformData (data) {
    var result = {
      PrescriptionDate: this.getValue(data, mappingTTools['Datum']),
      PrescriptionId: this.getValue(data, mappingTTools['Belegnummer']),
      PrescriptionState: this.getValue(data, mappingTTools['Rezeptstatus']),
      PersonName: this.getValue(data, mappingTTools['Kunde']),
      PrescriptionAmount: this.getIntValue(data, mappingTTools['Anzahl']),
      Remedy: this.getValue(data, mappingTTools['Therapieart']),
      Value: this.getFloatValue(data, mappingTTools['Preis']),
      CoPayment: this.getFloatValue(data, mappingTTools['Zuzahlung']),
      InvoiceDate: this.getValue(data, mappingTTools['Abrechnung Datum']),
      InvoiceNumber: this.getValue(data, mappingTTools['Rechnungsnummer (Einzel-/Sammelrechnung)']),
      InvoiceId: this.getValue(data, mappingTTools['Nr.']),
      RemedyPosition: this.getValue(data, mappingTTools['Heilmittelposition']),
      Therapists: this.getValue(data, mappingTTools['Therapeutennummer']),
      Remark: this.getValue(data, mappingTTools['Bemerkung'])
    }
    return result
  },

  getIntValue (data, key) {
    var result = this.getValue(data, key)
    if (result != null) {
      return parseInt(result)
    }
    return 0
  },

  getFloatValue (data, key) {
    var result = this.getValue(data, key)
    if (result != null) {
      return parseFloat(result.replace(',', '.'))
    }
    return 0.0
  },

  getValue (data, key) {
    if (key > -1 && key < data.length) {
      return data[key]
    }
    return null
  },

  validateData (data) {
    data.Errors = []
    var therapists = data.Therapists.split(',')
    if (therapists.length === 0 || (therapists.length === 1 && therapists[0].trim() === '')) {
      data.Errors.push('Keine Therapeutenangabe')
    } else if (therapists.length > 1 && therapists.length !== data.PrescriptionAmount) {
      data.Errors.push('Therapeutenangabe stimmt nicht mit der Anzahl der Behandlungen überein')
    }
  },

  transformBenefit (benefit) {
    var result = []
    // Nach Therapeuten splitten
    var therapists = benefit.Therapists.split(',').map(Function.prototype.call, String.prototype.trim)
    if (therapists.length === 1) {
      // Alle Termine wurden vom selben Therapeuten durchgeführt, keine Transformation notwendig
      var item = Object.assign({}, benefit)
      item.Therapists = therapists[0].trim()
      item.Uuid = uuidv4()
      result.push(item)
    } else if (therapists.length === benefit.PrescriptionAmount) {
      // Unterschiedliche Therapeuten: Erzeuge ein Item pro Behandlung
      var groupedTherapists = this.groupTherapists(therapists)
      groupedTherapists.forEach(group => {
        var item = Object.assign({}, benefit)
        item.Uuid = uuidv4()
        item.Therapists = parseInt(group.Therapist)
        item.Value = (item.Value / item.PrescriptionAmount) * group.Amount
        item.PrescriptionAmount = group.Amount
        result.push(item)
      })
    } else {
      // console.log(benefit.PrescriptionId + '|' + therapists.length + '|' + benefit.PrescriptionAmount)
    }
    return result
  },
  // Erzeugt aus einer Liste ein Dictonary mit der Anzahl der Vorkommen pro Schlüssel
  groupTherapists (therapists) {
    var result = []
    therapists.reduce(function (res, therapist) {
      var key = therapist
      if (!res[key]) {
        res[key] = { Therapist: key, Amount: 0 }
        result.push(res[key])
      }
      res[key].Amount++
      return res
    }, {})
    return result
  },
  // Gruppiert Leistungen pro Therapeut und summiert den Bruttobetrag
  groupBenefitsByTherapist (benefits) {
    var result = []
    benefits.reduce(function (res, benefit) {
      var key = benefit.Therapists
      if (!res[key]) {
        res[key] = { Therapist: key, Value: 0.0, Benefits: [] }
        result.push(res[key])
      }
      res[key].Value += benefit.Value
      res[key].Benefits.push(benefit)
      return res
    }, {})
    return result
  },
  // Gruppiert Leistungen eines Therapeuten pro Verordnung
  groupPrescriptionsByPrescription (benefits) {
    var result = []
    benefits.reduce(function (res, benefit) {
      var key = benefit.PrescriptionId
      if (!res[key]) {
        res[key] = benefit
        res[key].Benefits = []
        result.push(res[key])
      } else {
        res[key].Benefits.push(benefit)
        res[key].CoPayment += benefit.CoPayment
      }
      return res
    }, {})
    return result
  }
}
