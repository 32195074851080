import store from '@/store'
import Prescription from '@/models/prescription'

class PrescriptionService {

    get(id) {
        return store.getters.api.get(`prescriptions/${id}`)
            .then(response => {
                return new Prescription(response.data.data)
            })
    }

    save(p) {
        // clone the object to preserve icd10codes
        p = JSON.parse(JSON.stringify(p))
        // replace icd10code array with just the names
        if (p.icd10Codes)
            p.icd10Codes = p.icd10Codes.map(codeObj => codeObj.name)
        if (p.indicationKey)
            p.indicationKeyId = p.indicationKey.id
        if (p.prescriptionItems)
            p.prescriptionItems = p.prescriptionItems.map(pi => {
                return {
                    factor: 1,
                    remedyId: pi.remedy.id
                }
            })
        let promise = undefined
        if (p.id)
            promise = store.getters.api.put(`prescriptions/${p.id}`, p)
        else
            promise = store.getters.api.post(`prescriptions`, p)
        return promise.then(response => new Prescription(response.data.data))
    }

    deletePrescription(prescriptionId) {
        return store.getters.api.delete(`prescriptions/${prescriptionId}`)
    }
}

const prescriptionService = new PrescriptionService()

export default prescriptionService
