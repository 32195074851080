import axios from 'axios'
import store from '@/store'
import jwtDecoder from 'jwt-decode'
import moment from 'moment'

const LOCAL_STORAGE_API_KEY = 'key';
const LOCAL_STORAGE_TENANT = 'tenant';
const LOCAL_STORAGE_TOKEN = 'token';
const LOCAL_STORAGE_REFRESH_TOKEN = 'refreshToken';
const LOCAL_STORAGE_TOKEN_EXPIRES = 'tokenExpires';
const DATE_FORMAT = 'DD.MM.YYYY HH:mm'

const state = {
    state: {
        apiKey: null,
        tenant: null,
        token: null,
        refreshToken: null,
        isRefreshing: false,
        refreshingCall: null,
        expires: null
    },
    mutations: {
        apiKeyDetermined(state, apiKey) {
            if (apiKey) {
                state.apiKey = apiKey
                axios.defaults.headers.common['ApiKey'] = apiKey
                localStorage.setItem(LOCAL_STORAGE_API_KEY, apiKey);
                window.console.log('API key: ' + apiKey)
            }
        },
        tenantDetermined(state, tenant) {
            if (!tenant)
                tenant = ''
            state.tenant = tenant
            localStorage.setItem(LOCAL_STORAGE_TENANT, tenant)
            window.console.log('Tenant: ' + tenant)
            let baseUrl = process.env.VUE_APP_YOUTREAT_API_URL
            baseUrl = baseUrl.replace('[tenant]', tenant)
            axios.defaults.baseURL = baseUrl
            window.console.log('API URL: ' + baseUrl)
        },
        tokenDetermined(state, tokenObj) {
            if (tokenObj && tokenObj.token && tokenObj.token !== 'null') {
                state.token = tokenObj.token
                state.refreshToken = tokenObj.refreshToken
                let exp = jwtDecoder(state.token).exp
                state.expires = state.token ? moment.unix(exp).format(DATE_FORMAT) : moment().format(DATE_FORMAT)
                localStorage.setItem(LOCAL_STORAGE_TOKEN, state.token)
                localStorage.setItem(LOCAL_STORAGE_REFRESH_TOKEN, state.refreshToken)
                localStorage.setItem(LOCAL_STORAGE_TOKEN_EXPIRES, state.expires)
                axios.defaults.headers.common['Authorization'] =
                    'Bearer ' + tokenObj.token
            }
        },
        tokenInvalidated(state) {
            state.token = null
            state.refreshToken = null
            state.expires = null
            localStorage.removeItem(LOCAL_STORAGE_TOKEN)
            localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN)
            localStorage.removeItem(LOCAL_STORAGE_TOKEN_EXPIRES)
            this.commit('apiKeyDetermined', state.apiKey)
        },
        setRefreshingState(state, refreshingState) {
            state.isRefreshing = refreshingState
        },
        setRefreshingCall(state, refreshingCall) {
            state.refreshingCall = refreshingCall
        }
    },
    actions: {
        initApi({commit}) {
            commit('apiKeyDetermined', localStorage.getItem(LOCAL_STORAGE_API_KEY))
            commit('tenantDetermined', localStorage.getItem(LOCAL_STORAGE_TENANT))
            commit('tokenDetermined', {
                token: localStorage.getItem(LOCAL_STORAGE_TOKEN),
                refreshToken: localStorage.getItem(LOCAL_STORAGE_REFRESH_TOKEN),
                expires: moment(localStorage.getItem(LOCAL_STORAGE_TOKEN_EXPIRES), DATE_FORMAT)
            })
        }
    },
    getters: {
        token(state) {
            return state.token
        },
        apiKey(state) {
            return state.apiKey
        },
        tenant(state) {
            return state.tenant
        },
        api() {
            return axios
        },
        refreshToken() {
            return state.refreshToken
        },
        isRefreshing(state) {
            return state.isRefreshing
        },
        refreshingCall(state) {
            return state.refreshingCall
        }
    }
}

/*
function refreshToken() {
    if (store.getters.isRefreshing) {
        return store.getters.refreshingCall;
    }
    store.commit('setRefreshingState', true);
    const refreshingCall = axios.post('identity/refresh', {
        token: store.getters.token,
        refreshToken: store.getters.refreshToken,
    })
        .then(({data: {token}}) => {
            store.commit('tokenDetermined', token)
            return Promise.resolve(true);
        })
        .catch(error => console.log('token refresh failed with ' + error))
        .finally(() => {
            store.commit('setRefreshingState', false);
            store.commit('setRefreshingCall', undefined);
        });
    store.commit('setRefreshingCall', refreshingCall);
    return refreshingCall;
}
 */

/*
axios.interceptors.response.use(response => response, error => {
    const status = error.response ? error.response.status : null
    if (status === 401) {
        refreshToken()
            .then(() => {
                error.config.headers['Authorization'] = 'Bearer ' + store.getters.token;
                error.config.baseURL = undefined;
                return axios.request(error.config);
            })
            .catch(() => {
                store.commit('tokenInvalidated')
                store.commit('loginFailure')
            })
    }
    return Promise.reject(error);
});
 */

axios.interceptors.response.use(response => response, error => {
    const status = error.response ? error.response.status : null
    if (status === 401) {
        console.log('401!')
        store.dispatch('showMessage', {
            message: 'Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an!',
            type: 'warning'
        }).then(() => {
            store.commit('tokenInvalidated')
            store.commit('loginFailure')
        })
    }
    /*
        refreshToken()
            .then(() => {
                error.config.headers['Authorization'] = 'Bearer ' + store.getters.token;
                error.config.baseURL = undefined;
                return axios.request(error.config);
            })
            .catch(() => {
            })
    }
    return Promise.reject(error);
     */
});

axios.interceptors.request.use((config) => {
    config.paramsSerializer = (params) => JSON.stringify(params, {
        serializeDate: (date) => moment(date).format('YYYY-MM-DDTHH:mm:ssZ')
    });
    return config;
})

export default state