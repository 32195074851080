<template>
  <div class="px-1">
    <div class="text-right">
      <router-link tag="a" :to="{name: 'edit', params: {patientId: patient.id}}"
                   :append="true">
        <icon icon="edit"
              class="text-primary"/>
      </router-link>
    </div>
    <div>
      <div class="small col-3 text-right bg-info text-light">Allgemein</div>
    </div>
    <div class="row">
      <div class="col-3 text-right">
        KVNr:
      </div>
      <div class="col-9">
        {{ patient.insurantNumber }}
      </div>
    </div>
    <div class="row">
      <div class="col-3 text-right">
        Geburtsdatum:
      </div>
      <div class="col-9">
        {{ patient.dateOfBirth | date }} ({{ patient.getAge()}} Jahre)
      </div>
    </div>
    <div class="border-top mt-3">
      <div class="small col-3 mb-2 text-right bg-info text-light">Adresse</div>
      <div class="row">
        <div class="col-3 text-right">
          Straße:
        </div>
        <div class="col-9">
          {{ patient.street }}
        </div>
      </div>
      <div class="row">
        <div class="col-3 text-right">
          Hausnummer:
        </div>
        <div class="col-9">
          {{ patient.streetNumber }}
        </div>
      </div>
      <div class="row">
        <div class="col-3 text-right">
          Zusatz:
        </div>
        <div class="col-9">
          {{ patient.addition }}
        </div>
      </div>
      <div class="row">
        <div class="col-3 text-right">
          Postleitzahl:
        </div>
        <div class="col-9">
          {{ patient.zipcode }}
        </div>
      </div>
      <div class="row">
        <div class="col-3 text-right">
          Stadt:
        </div>
        <div class="col-9">
          {{ patient.city }}
        </div>
      </div>
    </div>
    <div class="border-top mt-3">
      <div class="small col-3 mb-2 text-right bg-info text-light">Kontakt</div>
      <div class="row">
        <div class="col-3 text-right">
          Telefon:
        </div>
        <div class="col-9">
          {{ patient.phone }}
        </div>
      </div>
      <div class="row">
        <div class="col-3 text-right">
          Mobil:
        </div>
        <div class="col-9">
          {{ patient.mobile }}
        </div>
      </div>
      <div class="row">
        <div class="col-3 text-right">
          Email:
        </div>
        <div class="col-9">
          {{ patient.email }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    patient: Object
  }
}
</script>

<style lang="scss">
@import '../../assets/styles/custom.scss';

.card-section-title {
  background-color: theme-color-level("info", 0);
  text-align: center;
  color: theme-color("light");
  padding: 0.3em 0 0.3em 0;
}

.card-section {
  margin-top: 0.5em;
  border: solid 1px theme-color("info");
  padding: 0;
}

</style>
