import moment from "moment";

export function toHtmlFormat(dateField) {
    return moment(dateField).format('YYYY-MM-DD')
}

export function fromHtmlFormat(valInHTMLFormat) {
    // hack to avoid time zone issues
    return moment(valInHTMLFormat + ' 16:00:00', 'YYYY-MM-DD HH:mm:ss')
}

export function fromThea(theaDateStr){
    return moment(theaDateStr, 'YYYY-MM-DD\'T\'HH:mm:ss')
}