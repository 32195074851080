<template>
  <div>
    <span v-for="(error, idx) in errors"
          :key="'error_npw_' + idx"
          class="form-error">
      {{ error }}<br>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    errors: Array
  }
}
</script>