<template>
  <div id="wrapper">
      <navigation v-if="!$route.meta.hideNavigation"/>
      <router-view
          :class="{'nav-p-top': !$route.meta.hideNavigation}"/>
      <messages/>
  </div>
</template>

<script>
import Navigation from "@/components/Navigation";
import Messages from "@/components/Messages";
import {mapGetters} from "vuex";

export default {
  name: 'TheaWebApp',
  computed: {
    ...mapGetters(['loggedIn'])
  },
  watch: {
    loggedIn(val) {
      if (val) {
        if (!this.$route.meta.next) {
          this.$router.push('/')
        } else {
          this.$router.push(this.$route.meta.next)
        }
      } else {
        this.$router.push({name: 'login'})
      }
    }
  },
  components: {
    Messages,
    Navigation
  }
}
</script>

<style lang="scss">
@import './assets/styles/custom.scss';
@import '../node_modules/bootstrap/scss/bootstrap.scss';

#wrapper {
  height: 100vh;
}

.nav-p-top {
  padding-top: 57px;
}

.fill {
  min-height: 100%;
  height: 100%;
}

</style>
