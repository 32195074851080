<template>
  <div class="container-fluid mx-0 fill">
    <div class="row fill">
      <div class="col-4 pt-2 bg-light h-100">
        <div>
          <button @click="newPatient"
                  class="btn btn-sm btn-primary">
            Neuer Patient
          </button>
        </div>
        <patient-list
            :patients="patients"
            :selected-patient="selectedPatient"
            :filter="filter"
            class="mt-2 h-100"
            @patientSelected="$router.push({name: 'patients', params: {patientId: $event.id}})"
            @filterChange="filter = $event"/>
      </div>
      <div class="col-8 pt-2 vertical-scroll h-100">
        <router-view :patient="selectedPatient"
                     @patientSaved="patientSaved($event)"
                     @patientDeleted="patientDeleted($event)"
                     @patientChanged="reloadPatient($event)">
        </router-view>
        <div v-if="!selectedPatient && this.$route.name !== 'newPatient'"
             class="mx-auto mt-5 w-50 text-center text-info">
          Wählen Sie einen Patienten, um die Akte anzusehen!
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PatientList from '@/components/patient/PatientList'
import patientService from '@/services/patient.service'
import {mapActions} from 'vuex'
import handleError from "@/services/error.service";

export default {
  props: {
    patientId: String
  },
  data() {
    return {
      patients: [],
      filter: '',
      selectedPatient: null
    }
  },
  methods: {
    ...mapActions(['handleError', 'showMessage']),
    loadSelectedPatient() {
      if (this.patientId) {
        patientService.getById(this.patientId)
            .then(p => {
              this.selectedPatient = p
            })
            .catch(error => handleError(error))
      }
    },
    updatePatients() {
      console.log('updating patient list')
      return patientService.getAllFiltered(this.filter)
          .then(patients => {
            this.patients = patients
          })
          .catch(error => this.handleError(error))
    },
    patientSaved(patient) {
      this.filter = ''
      this.showMessage({
        type: 'info',
        message: `Die Änderungen für ${patient.firstName} ${patient.lastName} wurden gespeichert`
      })
      this.selectedPatient = patient
      this.reloadPatient(patient.id)
      this.$router.push({name: 'patients', params: {patientId: patient.id}})
    },
    patientDeleted(name) {
      this.updatePatients().then(() => {
        this.$router.push({name: 'patients'})
        this.showMessage({
          type: 'info',
          message: `Patientenakte ${name} wurde gelöscht.`
        })
      })
    },
    newPatient() {
      this.$router.push({name: 'newPatient'})
    },
    reloadPatient(patientId) {
      patientService.getById(patientId)
          .then(reloadedPatient => {
            // update entry in list
            let listIdx = this.patients.findIndex(p => p.id === patientId)
            this.$set(this.patients, listIdx, reloadedPatient)
            this.selectedPatient = reloadedPatient
          })
          .catch(error => this.handleError(error))
    }
  },
  watch: {
    filter() {
      console.log('filter watch triggered')
      this.updatePatients()
    },
    patientId() {
      if (this.patientId) {
        this.loadSelectedPatient()
      } else {
        this.selectedPatient = null
      }
    }
  },
  mounted() {
    this.updatePatients().then(() => {
      this.loadSelectedPatient()
    })
  },
  components: {
    PatientList
  }
}
</script>
