let user = null;
try {
    user = JSON.parse(localStorage.getItem('user'))
} catch (e) {
    localStorage.removeItem('user')
}

export default {
    state: {
        user: user
    },
    mutations: {
        loginSuccess(state, identity) {
            state.user = identity
            localStorage.setItem('user', JSON.stringify(identity))
        },
        loginFailure(state) {
            state.user = null
        },
        loggedOut(state){
            state.user = null
            localStorage.removeItem('user')
        }
    },
    getters: {
        loggedIn(state) {
            return state.user != null
        },
        user(state) {
            return state.user
        },
        isAdmin(state){
            return state.user.isAdmin
        }
    }
}