import store from '@/store'
import Note from "@/models/note";

class NoteService {

    getNotesByPatient(id) {
        return store.getters.api.get(`persons/${id}/notes`)
            .then(response => {
                return response.data.data.map(n => new Note(n)).sort(Note.compare)
            })
    }

    saveNote(note) {
        let scope = note.prescriptionId ? 'prescriptions' : 'persons'
        let scopeObjectId = scope === 'prescriptions' ? note.prescriptionId : note.patientId
        let method = note.id ? 'put' : 'post'
        let type = scope === 'persons' ? 'notes' : 'appointmentNotes'
        let noteId = note.id ? `/${note.id}` : ''
        return store.getters.api[method](`${scope}/${scopeObjectId}/${type}${noteId}`, note)
    }

    deleteNote(note){
        return store.getters.api.delete(`persons/${note.personId}/notes/${note.id}`)
    }

    getNotesByPrescription(prescriptionId) {
        return store.getters.api.get(`prescriptions/${prescriptionId}/appointmentNotes`)
            .then(response => response.data.data.map(n => new Note(n)).sort(Note.compare))
    }

}

const noteService = new NoteService()

export default noteService
