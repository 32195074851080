<template>
  <div>
    <div>
      <div class="d-flex">
        <input
            v-model="filterInput"
            type="text"
            class="form-control"
            placeholder="Filter"/>
        <a href="#"
           @click.prevent="$emit('filterChange', '')">
          <icon icon="times-circle"
                class="pl-2 text-primary pointer-event"
                size="2x"/>
        </a>
      </div>
    </div>
    <div class="mt-2 p-1 h-100">
      <ul class="list-group w-100 pb-2 vertical-scroll h-75">
        <li v-for="patient in patients"
            :key="patient.id"
            class="list-group-item list-group-item-action py-1 d-inline-flex pl-2 disable-caret"
            :class="{active: (selectedPatient && selectedPatient.id === patient.id)}"
            @click="$router.push({name:'patients', params: {'patientId': patient.id}})">
          <div class="h-100">
            <icon icon="user"
                  size="1x"
                  :class="{'text-primary': !selectedPatient || selectedPatient.id != patient.id,
                  'text-light': selectedPatient && selectedPatient.id == patient.id}"/>
          </div>
          <div class="d-block ml-1">
            {{ `${patient.lastName}, ${patient.firstName}` }}
            <p class="text-secondary small mb-0">{{ patient.dateOfBirth | date }}</p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    patients: Array,
    selectedPatient: Object,
    filter: String
  },
  data(){
    return {
      filterInput: ''
    }
  },
  watch: {
    filter(newVal){
      this.filterInput = newVal
    },
    filterInput(newVal){
      this.$emit('filterChange', newVal)
    }
  },
  mounted(){
    this.filterInput = this.filter;
  }
}
</script>
