import Vue from 'vue'
import Vuex from 'vuex'
import authentication from "@/store/authentication.store";
import api from "@/store/api.store";
import messages from "@/store/messages.store"

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    api, authentication, messages
  }
})