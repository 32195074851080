import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import PatientsView from "@/views/PatientsView";
import PatientFile from "@/components/patient/PatientFile";
import PatientDetail from "@/components/patient/PatientDetail";
import PatientPrescriptions from "@/components/patient/PatientPrescriptions";
import PatientDetailEditor from "@/components/patient/PatientDetailEditor";
import PatientNotes from "@/components/patient/PatientNotes";
import NoteEditor from "@/components/note/NoteEditor";
import PrescriptionEditor from "@/components/prescription/PrescriptionEditor";
import AppointmentsView from "@/views/AppointmentsView";
import UserManagementView from "@/views/UserManagementView";
// thea statistics
import StatsView from "../views/StatsView";
import ManualView from "../views/ManualView";
import DownloadView from "../views/DownloadView";

Vue.use(VueRouter)

const routes = [
    {
        path: '/anmelden',
        name: 'login',
        component: () => import('../views/LoginView.vue'),
        meta: {
            hideNavigation: true,
            requiresAuth: false,
            section: 'none'
        }
    },
    {
        path: '/patienten/:patientId?',
        name: 'patients',
        alias: '/',
        component: PatientsView,
        props: true,
        meta: {
            requiresAuth: true,
            section: 'patients'
        },
        redirect: {
            name: 'patientFile'
        },
        children: [
            {
                path: 'neu',
                name: 'newPatient',
                component: PatientDetailEditor,
                params: {
                    patientId: 0
                }
            },
            {
                alias: '',
                path: 'akte',
                name: 'patientFile',
                props: true,
                component: PatientFile,
                meta: {
                    fileSection: 'detail'
                },
                redirect: {
                    name: 'patientDetail'
                },
                children: [
                    {
                        alias: '',
                        path: 'detail',
                        props: true,
                        name: 'patientDetail',
                        meta: {
                            fileSection: 'detail'
                        },
                        component: PatientDetail
                    },
                    {
                        path: 'dokumentation',
                        props: true,
                        name: 'patientNotes',
                        component: PatientNotes,
                        meta: {
                            fileSection: 'notes'
                        },
                        children: [
                            {
                                path: 'neu',
                                props: true,
                                name: 'newPatientNote',
                                meta: {
                                    fileSection: 'notes'
                                },
                                component: NoteEditor
                            }
                        ]
                    },
                    {
                        path: 'verordnungen',
                        props: true,
                        name: 'patientFilePrescriptions',
                        meta: {
                            fileSection: 'prescriptions'
                        },
                        component: PatientPrescriptions,
                        children: [
                            {
                                path: ':prescriptionId/bearbeiten',
                                props: true,
                                name: 'patientFilePrescriptionEdit',
                                meta: {
                                    fileSection: 'prescriptions'
                                },
                                component: PrescriptionEditor
                            },
                            {
                                path: 'neu',
                                props: true,
                                name: 'patientFilePrescriptionNew',
                                meta: {
                                    fileSection: 'prescriptions'
                                },
                                component: PrescriptionEditor
                            },
                            ]
                    }
                ]
            },
            {
                path: 'bearbeiten',
                name: 'edit',
                meta: {
                    fileSection: 'detail'
                },
                component: PatientDetailEditor
            }
        ]
    },
    {
        path: '/termine',
        name: 'appointments',
        component: AppointmentsView,
        meta: {
            section: 'appointments',
            requiresAuth: true
        }
    },
    {
        path: '/benutzerverwaltung',
        name: 'usermanagement',
        component: UserManagementView,
        meta: {
            section: 'usermanagement',
            requiresAuth: true
        }
    },
    {
        path: '/passwort-aendern',
        name: 'changepassword',
        component: () => import('../views/ChangePasswordView'),
        meta: {
            section: 'none',
            requiresAuth: true
        }
    },
    {
        path: "/download",
        name: "download",
        component: DownloadView,
        meta: {
            title: "Thea App - Download",
            requiresAuth: false,
            hideNavigation: true,
        },
    },
    {
        path: "/stats",
        name: "stats",
        component: StatsView,
        meta: {
            title: "Thea Stats - Mitarbeiterstatistik",
            requiresAuth: false,
            hideNavigation: true,
        },
    },
    {
        path: "/manual",
        name: "manual",
        component: ManualView,
        meta: {
            title: "Thea Stats - Anleitung",
            requiresAuth: false,
            hideNavigation: true,
        },
    },
]

const router = new VueRouter({
    mode: 'history',
    scrollBehavior() {
        return {x: 0, y: 0}
    },
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    let log = window.console.log
    log(from.path + ' --> ' + to.path)
    log('checking authentication...')
    if (store.getters.loggedIn) {
        log('...authenticated! route to ' + to.path)
        next()
    } else {
        if (to.matched.some(route => route.meta.requiresAuth)) {
            let loginRoute = routes[0]
            loginRoute.meta.next = to
            log('-->' + loginRoute.path + ' (next:' + loginRoute.meta.next.path + ')')
            next(loginRoute)
        } else {
            next()
        }
    }
})

export default router
