import store from '@/store'

class MasterDataService {

    getIcd10Codes() {
        return store.getters.api.get('icd10codes')
            .then(response => {
                return response.data
            })
    }

    getIndicationKeys() {
        return store.getters.api.get('indicationKeys')
            .then(response => {
                return response.data
            })
    }

    getRemedies(){
        return store.getters.api.get('remedies')
            .then(response => {
                return response.data
            })
    }

}

const masterDataService = new MasterDataService()

export default masterDataService
