<template>
  <div>
    <div class="text-right"
         v-if="$route.name === 'patientFilePrescriptions'">
      <router-link :to="{name: 'patientFilePrescriptionNew', params: { patientId: patient.id}}"
                   tag="a"
                   class="btn btn-primary btn-sm mb-2">
        Neue Verordnung
      </router-link>
    </div>
    <div v-if="$route.name === 'patientFilePrescriptionEdit'
              || $route.name==='patientFilePrescriptionNew'">
      <router-view @prescriptionSaved="prescriptionChanged($event)"/>
    </div>
    <div v-else>
      <div v-for="prescription in patient.prescriptions"
           :key="prescription.id"
           class="mb-2">
        <prescription-detail :prescription-id="prescription.id"
                             :key="prescription.id"
                             @prescriptionDeleted="prescriptionDeleted($event)"/>
      </div>
      <div v-if="patient.prescriptions.length === 0"
           class="text-secondary">
        Noch keine Verordnungen
      </div>
    </div>
  </div>
</template>

<script>
import PrescriptionDetail from "@/components/prescription/PrescriptionDetail";
import {mapActions} from 'vuex'

export default {
  props: {
    patient: Object
  },
  methods: {
    ...mapActions(['showMessage']),
    prescriptionDeleted() {
      this.showMessage({
        type: 'info',
        message: 'Die Verordnung wurde gelöscht'
      })
      this.patientChanged()
    },
    prescriptionChanged() {
      this.showMessage({
        type: 'info',
        message: 'Ihre Änderungen wurden gespeichert'
      })
      // go back to non-edit mode
      this.$router.push({name: 'patientFilePrescriptions', params: {'patientId': this.patient.id}})
          .then(() => this.patientChanged())
    },
    patientChanged() {
      this.$emit('patientChanged', this.patient.id)
    }
  },
  components: {
    PrescriptionDetail
  }

}
</script>