<template>
  <div v-if="editedPatient">
    <div class="card">
      <div class="card-header">
        <div>
          <icon icon="file-alt"
                size="2x"
                class="py-1 mr-1 text-info align-middle"/>
          <span v-if="editedPatient.id">
           {{ editedPatient.firstName }} {{ editedPatient.lastName }} Patientenakte bearbeiten
          </span>
          <span v-else>Neuer Patient</span>
        </div>
      </div>
      <div class="card-body">
        <validation-observer tag="form" v-slot="{invalid}" ref="formStatus"
                             @submit.prevent="save()"
                             @keydown.enter.prevent>
          <div class="form-group row">
            <div class="col-6">
              <label for="firstNameInput">Vorname</label>
              <validation-provider vid="FirstName" name="Vorname" rules="required" v-slot="{errors}">
                <input
                    v-model="editedPatient.firstName"
                    id="firstNameInput"
                    class="form-control"
                    type="text"
                    autofocus
                    ref="firstInput"
                    :class="{'is-invalid': errors.length > 0}"/>
                <form-errors :errors="errors"></form-errors>
              </validation-provider>
            </div>
            <div class="col-6">
              <label for="lastNameInput">Nachname</label>
              <validation-provider vid="LastName" name="Nachname" rules="required" v-slot="{errors}">
                <input
                    v-model="editedPatient.lastName"
                    id="lastNameInput"
                    class="form-control"
                    type="text"
                    :class="{'is-invalid': errors.length > 0}"/>
                <form-errors :errors="errors"></form-errors>
              </validation-provider>
            </div>
          </div>
          <!-- KVNr -->
          <div class="form-group row">
            <div class="col-6">
              <label for="insurantNumberInput">KVNr</label>
              <validation-provider vid="InsurantNumber" name="KVNr." v-slot="{errors}">
                <input
                    v-model="editedPatient.insurantNumber"
                    id="insurantNumberInput"
                    class="form-control"
                    type="text"
                    :class="{'is-invalid': errors.length > 0}"/>
                <form-errors :errors="errors"></form-errors>
              </validation-provider>
            </div>
            <div class="col-6">
              <label for="dateOfBirthInput">Geburtstdatum</label>
              <validation-provider vid="DateOfBirth" name="Geburtstag" v-slot="{errors}">
                <input id="dateOfBirthInput"
                       type="date"
                       class="form-control"
                       v-model="dateOfBirthValue"
                       :class="{'is-invalid': errors.length > 0}"
                       ref="dateOfBirthInput"/>
                <form-errors :errors="errors"></form-errors>
              </validation-provider>
            </div>
          </div>
          <!-- address -->
          <!-- street -->
          <div class="form-group row">
            <div class="col-4">
              <label for="streetInput">Straße</label>
              <validation-provider vid="Street" name="Straße" v-slot="{errors}">
                <input
                    v-model="editedPatient.street"
                    id="streetInput"
                    class="form-control"
                    type="text"
                    :class="{'is-invalid': errors.length > 0}"/>
                <form-errors :errors="errors"></form-errors>
              </validation-provider>
            </div>
            <!-- streetnumber -->
            <div class="col-2">
              <label for="streetNumberInput">Nr.</label>
              <validation-provider vid="StreetNumber" name="Hausnummer" v-slot="{errors}">
                <input
                    v-model="editedPatient.streetNumber"
                    id="streetNumberInput"
                    class="form-control"
                    type="text"
                    :class="{'is-invalid': errors.length > 0}"/>
                <form-errors :errors="errors"></form-errors>
              </validation-provider>
            </div>
            <!-- addition -->
            <div class="col-6">
              <label for="additionInput">Zusatz</label>
              <validation-provider name="Zusatz" v-slot="{errors}">
                <input
                    v-model="editedPatient.addition"
                    id="additionInput"
                    class="form-control"
                    type="text">
                <span class="form-error">{{ errors[0] }}</span>
              </validation-provider>
            </div>
            <!-- zip -->
            <div class="col-2">
              <label for="zipInput">PLZ</label>
              <validation-provider mode="lazy" vid="Zipcode" name="Postleitzahl" rules="zip" v-slot="{errors}">
                <input
                    v-model="editedPatient.zipcode"
                    id="zipInput"
                    class="form-control"
                    type="text"
                    :class="{'is-invalid': errors.length > 0}"/>
                <form-errors :errors="errors"></form-errors>
              </validation-provider>
            </div>
            <!-- city -->
            <div class="col-4">
              <label for="cityInput">Stadt</label>
              <validation-provider vid="City" name="Stadt" v-slot="{errors}">
                <input
                    v-model="editedPatient.city"
                    id="cityInput"
                    class="form-control"
                    type="text"
                    :class="{'is-invalid': errors.length > 0}"/>
                <form-errors :errors="errors"></form-errors>
              </validation-provider>
            </div>
          </div>
          <!-- email -->
          <div class="form-group row">
            <div class="col-6">
              <label for="emailInput">Email</label>
              <validation-provider vid="Email" name="Email" rules="email" v-slot="{errors}">
                <input
                    v-model="editedPatient.email"
                    id="emailInput"
                    class="form-control"
                    type="text"
                    :class="{'is-invalid': errors.length > 0}"/>
                <form-errors :errors="errors"></form-errors>
              </validation-provider>
            </div>
          </div>
          <!-- phone/mobile -->
          <div class="form-group row">
            <!-- phone -->
            <div class="col-6">
              <label for="phoneInput">Telefon</label>
              <validation-provider vid="Phone" mode="lazy" name="Telefon" rules="phone" v-slot="{errors}">
                <input
                    v-model="editedPatient.phone"
                    id="phoneInput"
                    class="form-control"
                    type="text"
                    :class="{'is-invalid': errors.length > 0}"/>
                <form-errors :errors="errors"></form-errors>
              </validation-provider>
            </div>
            <!-- mobile -->
            <div class="col-6">
              <label for="mobileInput">Mobil</label>
              <validation-provider vid="Mobile" name="Mobilnummer" rules="phone" v-slot="{errors}">
                <input
                    v-model="editedPatient.mobile"
                    id="mobileInput"
                    class="form-control"
                    type="text"
                    :class="{'is-invalid': errors.length > 0}"/>
                <form-errors :errors="errors"></form-errors>
              </validation-provider>
            </div>
          </div>
          <div class="d-block text-right">
            <router-link tag="button" type="button"
                         :to="{name: 'patientDetail', params: {patientId: editedPatient.id}}"
                         class="btn btn-secondary"
                         tabindex="-1">
              Abbrechen
            </router-link>
            <button type="submit"
                    class="btn btn-primary ml-2"
                    :disabled="invalid"
                    @keydown.enter="save"
                    @blur="$refs.firstInput.focus()">
              Speichern
            </button>
          </div>
        </validation-observer>
      </div>
    </div>
  </div>
</template>

<script>
import patientService from '@/services/patient.service'
import Patient from '@/models/patient'
import {toHtmlFormat, fromHtmlFormat} from "@/utils/date-converter";
import {mapActions} from 'vuex'
import FormErrors from "@/components/common/FormErrors";
import handleFormError from "@/utils/form-error-converter";

export default {
  props: {
    patient: Object
  },
  data() {
    return {
      editedPatient: null,
      saved: false
    }
  },
  computed: {
    dateOfBirthValue: {
      get: function () {
        if (!this.editedPatient.dateOfBirth)
          return this.editedPatient.dateOfBirth
        return toHtmlFormat(this.editedPatient.dateOfBirth)
      },
      set: function (valInHTMLFormat) {
        console.log(valInHTMLFormat)
        if (valInHTMLFormat && valInHTMLFormat.length > 0 && !isNaN(parseInt(valInHTMLFormat[0]))) {
          this.editedPatient.dateOfBirth = fromHtmlFormat(valInHTMLFormat)
          // this.$refs.dateOfBirthInput.value = "2020-03-02"
        }
      }
    }
  },
  methods: {
    ...mapActions(['handleError']),
    refreshForm() {
      if (this.patient) // can be null right after mount
        this.editedPatient = JSON.parse(JSON.stringify(this.patient))
      else
        this.editedPatient = new Patient()
      this.saved = false
    },
    save() {
      if (!this.editedPatient.dateOfBirth)
        delete this.editedPatient.dateOfBirth
      let vm = this
      patientService.save(this.editedPatient).then((p) => {
        vm.saved = true
        this.editedPatient = p
        vm.$emit('patientSaved', p)
      }).catch(error => {
        if (!handleFormError(error, this.$refs.formStatus))
          this.handleError(error)
      })
    }
  },
  watch: {
    // required if url directly input
    patient() {
      this.refreshForm()
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.$refs.formStatus.flags.changed && !this.saved) {
      this.$dialog.confirm({
        title: 'Wirklich abbrechen?',
        body: 'Sie haben Änderungen vorgenommen. Wollen Sie diese wirklich verwerfen?'
      }, {
        reverse: false,
        okText: 'Weiter bearbeiten',
        cancelText: 'Bearbeitung abbrechen'
      })
          .then()
          .catch(() => next())
    } else {
      next();
    }
  },
  mounted() {
    this.refreshForm()
  },
  components: {
    FormErrors
  }
}
</script>
