import Vue from 'vue'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {library} from '@fortawesome/fontawesome-svg-core'
import {
    faTimesCircle,
    faUser,
    faFileAlt,
    faEdit,
    faPowerOff,
    faSave,
    faPlusCircle,
    faAngleLeft,
    faCog
} from '@fortawesome/free-solid-svg-icons'

library.add(faCog, faTimesCircle, faUser, faFileAlt, faEdit, faPowerOff, faSave, faPlusCircle, faAngleLeft)

Vue.component('icon', FontAwesomeIcon)

